var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canViewVerticalNavMenuLink(_vm.item))?_c('li',{staticClass:"nav-item my-25",class:{
    active: _vm.isActiveItem(_vm.item) || _vm.isChild(_vm.item),
    disabled: _vm.item.disabled,
  }},[(_vm.item.route === 'auditorium' && _vm.mainRoom != null)?_c('b-link',{staticClass:"nav-link",class:{
      'bg-light rounded':
        _vm.item.title === _vm.$t('backoffice.back') || _vm.item.title === 'Back',
    },attrs:{"disabled":_vm.isEditingLayout,"to":_vm.isEditingLayout
        ? ''
        : { name: _vm.item.route, params: { name: _vm.mainRoom.name } }}},[(_vm.item.title === _vm.$t('backoffice.back') || _vm.item.title === 'Back')?_c('feather-icon',{staticClass:"mt-lg-25",attrs:{"icon":"ArrowLeftIcon","size":"14"}}):_vm._e(),(!_vm.item.icon || _vm.item.icon.includes('fa'))?_c('feather-icon',{attrs:{"icon":"CoffeeIcon","size":"20"}}):_vm._e(),(_vm.item.icon)?_c('feather-icon',{attrs:{"icon":_vm.item.icon,"size":"20"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.translate(_vm.title(_vm.item))))])],1):_c('b-link',{staticClass:"nav-link",class:{
      'bg-light rounded':
        _vm.item.title === _vm.$t('backoffice.back') ||
        _vm.item.title === 'Back' ||
        _vm.item.title === 'Home',
    },attrs:{"disabled":_vm.isEditingLayout,"to":_vm.isEditingLayout
        ? ''
        : typeof _vm.item.route === 'string'
          ? { name: _vm.item.route }
          : _vm.item.route}},[(!_vm.item.icon || _vm.item.icon.includes('fa'))?_c('feather-icon',{attrs:{"icon":"CoffeeIcon","size":"20"}}):_vm._e(),(_vm.item.icon)?_c('feather-icon',{attrs:{"icon":_vm.item.icon,"size":"20"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.translate(_vm.title(_vm.item))))])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }