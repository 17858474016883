<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item my-25"
    :class="{
      active: isActiveItem(item) || isChild(item),
      disabled: item.disabled,
    }"
  >
    <b-link
      v-if="item.route === 'auditorium' && mainRoom != null"
      class="nav-link"
      :class="{
        'bg-light rounded':
          item.title === $t('backoffice.back') || item.title === 'Back',
      }"
      :disabled="isEditingLayout"
      :to="
        isEditingLayout
          ? ''
          : { name: item.route, params: { name: mainRoom.name } }
      "
    >
      <feather-icon
        v-if="item.title === $t('backoffice.back') || item.title === 'Back'"
        icon="ArrowLeftIcon"
        size="14"
        class="mt-lg-25"
      />
      <feather-icon
        v-if="!item.icon || item.icon.includes('fa')"
        icon="CoffeeIcon"
        size="20"
      />
      <feather-icon v-if="item.icon" :icon="item.icon" size="20" />
      <span>{{ translate(title(item)) }}</span>
    </b-link>
    <b-link
      v-else
      class="nav-link"
      :class="{
        'bg-light rounded':
          item.title === $t('backoffice.back') ||
          item.title === 'Back' ||
          item.title === 'Home',
      }"
      :disabled="isEditingLayout"
      :to="
        isEditingLayout
          ? ''
          : typeof item.route === 'string'
            ? { name: item.route }
            : item.route
      "
    >
      <feather-icon
        v-if="!item.icon || item.icon.includes('fa')"
        icon="CoffeeIcon"
        size="20"
      />
      <feather-icon v-if="item.icon" :icon="item.icon" size="20" />
      <span>{{ translate(title(item)) }}</span>
    </b-link>
  </li>
</template>

<script>
import { BLink, BBadge } from 'bootstrap-vue';
import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item,
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    itemsData() {
      return this.$store.getters.rooms;
    },
    mainRoom() {
      if (this.itemsData) {
        return this.itemsData.unpaginated.find((room) => room.isMain === true);
      }
      return {};
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCommunity() {
      return this.$store.getters.mainCollective;
    },
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.locale, field);
    },
    title(item) {
      if (item.customization?.customizationName) {
        return item.customization.customizationName;
      }
      return item.title;
    },
    isChild(item) {
      if (
        item.title === this.$t('backoffice.roles.title')
        && this.$route.name === 'role-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.roles.my-roles')
        && this.$route.name === 'my-role-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('members.page-title.members-list')
        && this.$route.name === 'backoffice-members-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.classifiers.name')
        && this.$route.name === 'classifier-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.communication.forms.title')
        && this.$route.name === 'forms-bo-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.custom-fields.name')
        && this.$route.name === 'custom-fields-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.feedback.title')
        && this.$route.name === 'feedback-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('members.page-title.members-list')
        && this.$route.name === 'organization-members-details'
      ) {
        return true;
      }
      if (
        item.title === this.$t('backoffice.faqs.title')
        && this.$route.name === 'faqs-details'
      ) {
        return true;
      }
      if (item.title === 'App Store' && this.$route.name === 'addon') {
        return true;
      }
      if (item.title === 'App Store' && this.$route.name === 'integration') {
        return true;
      }
      if (
        item.title === this.$t('members.edit.organizations.label')
        && this.$route.name === 'organization'
      ) {
        return true;
      }
      return false;
    },
    isActiveItem(item) {
      if (this.$route.name === 'events') {
        if (this.$route?.query?.display) {
          if (this.$route?.query?.display?.toString() === item.route?.query?.display?.toString()) {
            return true;
          }
          return false;
        }
        if (item.route === this.$route.name) {
          return true;
        }
        return false;
      }
      if (this.$route.name === 'people' || this.$route.name === 'entity') {
        if (this.$route.name === item.route.name && item.route?.params?.typeKey?.toString() === this.$route?.params?.typeKey?.toString()) {
          return true;
        }
        return false;
      }
      if (item.route === 'articles') {
        if (this.$route?.name === 'articles' || this.$route?.name === 'article') {
          return true;
        }
        return false;
      }
      if (this.$route.path.indexOf('backoffice') < 0) {
        this.updateIsActive(item);
        return this.isActive;
      }
      if (item.route === this.$route.name) {
        return true;
      }
      return false;
    },
  },
};
</script>
